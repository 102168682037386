






























































import { Component, Prop, Watch } from "vue-property-decorator";
import ProductService from "@/services/product_service";
import UserService from "@/services/user_service";
import { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";

@Component
export default class Share extends mixins(Auth) {
  @Prop({ default: false }) showDialog!: boolean;
  @Prop() shareLink!: string;
  protected productService = new ProductService();
  protected userService = new UserService();
  protected show = false;
  protected copied = false;
  protected emailed = false;
  protected shareEmails = "";
  protected showEmailWarning = false;

  @Watch("showDialog")
  protected isOpen(): void {
    this.show = this.showDialog;
    this.copied = false;
    this.emailed = false;
    this.shareEmails = "";
  }

  protected closeDialog(): void {
    this.show = false;
    this.showDialog = false;
    this.$emit('close');
  }

  protected copy(): void {
    const selectEl = this.$refs.linkElement as HTMLInputElement;
    selectEl.select();
    document.execCommand("copy");
    this.copied = true;
  }

  protected async sendEmail(): Promise<void> {
    if (this.shareEmails === '') {
      this.showEmailWarning = true;
    } else {
      this.emailed = false;
      this.showEmailWarning = false;
      if (this.user) {
        this.emailed = await this.productService.shareShadeDesign(
          this.shareLink,
          this.shareEmails,
          this.user
        );
      }
    }
  }
}

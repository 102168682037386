export interface FabricSwatch {
  _id: string;
  name: string;
  mfr_code: string;
  frankford_code?: string;
  fabric_scale: number;
  mfr: FabricManufacturer;
  mfr_handle: string;
  image: string;
  brand?: string;
  brand_handle?: string;
  brand_collection?: string;
  brand_collection_handle?: string;
  select?: boolean;
  index?: number;
  grade: FabricGrade;
  fabric_type?: FabricType;
  color_group?: string[];
  pattern_group?: string[];
  inactive?: boolean;
  weight?: string;
  discontinued?: boolean;
  comparisons?: | FabricSwatch[]; 
}

export type FabricResponse = {
  [K in FabricGrade]: FabricObject;
};

export interface FabricObject {
  hande: string;
  display_name: string;
  fabrics?: FabricSwatch[];
}
export type FabricGrade = "a" | "ap" | "a/ap" | "b" | "c" | "d" | "e" | "f";
// API doesn't return as

type FabricType = "acrylic" | "vinyl";

export interface FabricMfr {
  mfr: FabricManufacturer
}

export enum FabricManufacturer {
  Recasens = "recasens",
  Sunbrella = "sunbrella",
  Sattler = "sattler"
}

export interface FabricFilter {
  grade: FabricGrade[];
  color: string[];
  design: string;
  mfr: string;
  weight: string;
}

type ArrayLengthMutationKeys = "splice" | "push" | "pop" | "shift" | "unshift";
type ArrayItems<T extends Array<FabricSwatch>> = T extends Array<infer TItems>
  ? TItems
  : never;
type FixedLengthArray<T extends FabricSwatch[]> = Pick<
  T,
  Exclude<keyof T, ArrayLengthMutationKeys>
> & { [Symbol.iterator]: () => IterableIterator<ArrayItems<T>> };
//implements fixed length arrays https://stackoverflow.com/questions/41139763/how-to-declare-a-fixed-length-array-in-typescript

export type FixedFabricArray =
  | FixedLengthArray<[FabricSwatch]>
  | FixedLengthArray<[FabricSwatch, FabricSwatch]>;





















































import { Auth } from "@/mixins/auth";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
/** SEE PRICING DIAGRAM FOR MORE INFORMATION */
@Component
export default class PricingBreakdown extends mixins(Auth) {
  @Prop() umbrellaName!: string;
  @Prop() umbrellaModel!: string;
  @Prop() baseName!: string | null;
  @Prop() baseModel!: string | null;
  @Prop({ default: 0 }) total!: number;
  @Prop({ default: 0 }) umbrellaPrice!: number;
  @Prop({ default: 0 }) basePrice!: number;
}

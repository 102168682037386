import { UserState } from "./types";
import { User, UserProfileSchema } from "@/models/user/user";

export const setUser = (state: UserState, data: User | null) => {
  state.user = data;
};

export const setUserProfile = (state: UserState, data: UserProfileSchema) => {
  const updatedUser = { ...state.user! };
  updatedUser.first_name = data.first_name;
  updatedUser.last_name = data.last_name;
  updatedUser.email = data.email;
  updatedUser.customer_info = data.customer_info;
  state.user = updatedUser;
};

export const setFileId = (state: UserState, data: string | null) => {
  state.fileBeingEdited = data;
};

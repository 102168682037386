



















































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FabricFilter from "@/components/designer/fabric/picker/FabricFilter.vue";
import FabricService from "@/services/fabric_service";
import * as fabric from "@/models/products/fabric";
import Swatch from "@/components/designer/fabric/Swatch.vue";
import { FabricComponent } from "@/models/configurator/fabric_components";
import { mixins } from "vue-class-component";
import { CanopyStore, LayoutStore, ShapeStore } from "@/mixins/store";
import { APIError, NotFoundError } from "@/services/error_service";
import { StockMixin } from "@/mixins/stock";
import { FabricMixin } from "@/mixins/fabric";
import * as option from "@/models/configurator/configurator_options";
import { LayoutRules } from "@/mixins/business_logic/layout/layout_rules";
import FabricComparisonDisclaimer from "@/components/designer/reusable/FabricComparisonDisclaimer.vue";


@Component({
  components: {
    FabricFilter,
    Swatch,
    FabricComparisonDisclaimer,
  },
})
export default class FabricSelector extends mixins(
  CanopyStore,
  ShapeStore,
  LayoutStore,
  StockMixin,
  FabricMixin,
  LayoutRules
) {
  @Prop({ default: null }) component!: FabricComponent | null;
  protected selected: fabric.FabricSwatch | null = null;
  protected fabricService = new FabricService();
  protected fabrics: fabric.FabricSwatch[] = [];
  protected searchTerm = "";
  protected fabricComponent = FabricComponent;
  protected solidsOnly = false;
  protected noStripes = false;
  protected filters: fabric.FabricFilter = {
    grade: ["a"],
    color: [],
    design: "",
    mfr: "",
    weight: "",
  };
  protected isCustomFabricWeight = false;
  protected comparisonModalOpen = false;
  protected comparisonFabric: fabric.FabricSwatch | null = null;
  protected unfilteredFabrics: fabric.FabricSwatch[] = [];


  protected modalTop = "0px";
  protected modalLeft = "0px";
  protected modalWidth = "0px";

  protected ulWidth = "100%";
  protected ulLeft = "0px";

  protected setULDimensions(event: MouseEvent): void {
    const ulElement = (event.currentTarget as HTMLElement).closest("ul");
    const liElement = (event.currentTarget as HTMLElement).closest("li");

    if (ulElement && liElement) {
      const ulRect = ulElement.getBoundingClientRect();
      const liRect = liElement.getBoundingClientRect();

      // Correctly align modal with UL, adjusting for the LI offset
      this.ulWidth = `${ulRect.width}px`;
      this.ulLeft = `${ulRect.left - liRect.left}px`; // Offset LI's left position
    }
  }

  protected openComparisonModal(fabric: fabric.FabricSwatch, event: MouseEvent): void {
    if (fabric.mfr.toLowerCase() === "recasens") {
      this.select(fabric);
      return;
    }
    const listItem = (event.currentTarget as HTMLElement).closest("li");

    if (listItem) {
      const rect = listItem.getBoundingClientRect();
      const parentRect = listItem.parentElement?.getBoundingClientRect();

      this.modalTop = `${rect.bottom + window.scrollY}px`;
      this.modalLeft = `${parentRect ? parentRect.left + window.scrollX : rect.left}px`;
      this.modalWidth = `${parentRect ? parentRect.width : rect.width}px`;
    }

    if (this.comparisonFabric?.mfr_code === fabric.mfr_code) {
      this.comparisonModalOpen = !this.comparisonModalOpen;
      this.comparisonFabric = this.comparisonModalOpen ? fabric : null;
    } else {
      this.comparisonModalOpen = true;
      this.comparisonFabric = fabric;
    }
  }

protected closeComparisonModal(): void {
  this.comparisonModalOpen = false;
  this.comparisonFabric = null;
}

protected toggleComparisonModal(fabric: fabric.FabricSwatch, event: MouseEvent): void {
  if (fabric.mfr.toLowerCase() === "recasens") {
    // Prevent showing the modal for recasens swatches
    this.select(fabric);
    this.setULDimensions(event); // Ensure dimensions are updated
    return;
  }
  this.setULDimensions(event); // Ensure dimensions are updated
  this.select(fabric);
  
  if (this.comparisonFabric?.mfr_code === fabric.mfr_code) {
    this.comparisonModalOpen = true;
  } else {
    this.comparisonModalOpen = true;
    this.comparisonFabric = fabric;
  }
}



  /** Switcher that executes logic based on the umbrella component we are selecting a fabric for
   * Watcher on this.component, which changes when user opens fabric selector modal
   */

  @Watch("isStockOnly")
  protected async stockChange(): Promise<void> {
    if (this.isStockOnly) {
      this.allowValance(false);
      this.allowVent(false);
      await this.setGrade("a");
      //EventBus.$emit("changePocket", false);
    }
  }

  @Watch("component")
  protected onPropUpdate(): void {
    this.showStripes();
    this.solidsOnly = false;
    if (
      this.umbrellaModel!.model.indexOf("-R") > -1 ||
      this.component === this.fabricComponent.VentTrim ||
      this.component === this.fabricComponent.MainTrim ||
      this.component === this.fabricComponent.MainTrimInner
    ) {
      this.noStripes = true;
    }
    if (this.component) {
      switch (this.component) {
        case this.fabricComponent.Main:
          this.selected = this.mainFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MainAlternating:
          this.selected = this.mainFabric[1]
            ? this.mainFabric[1]
            : this.mainFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.TopVent:
          this.selected = this.ventTopFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MiddleVent:
          this.selected = this.ventMiddleFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MiddleAlternating:
          this.selected = this.ventMiddleFabric[1]
            ? this.ventMiddleFabric[1]
            : this.ventMiddleFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.Binding:
          this.setDesign("Solids");
          this.selected = this.bindingFabric;
          this.solidsOnly = true;
          this.noStripes = true;
          this.filters = this.getFilters(this.component);
          break;
        // case this.fabricComponent.Pockets:
        //   this.selected = this.ribFabric;
        //   this.filters = this.getFilters(this.component);
        //   this.noStripes = true;
        //   break;
        case this.fabricComponent.VentTrim:
          this.selected = this.ventTrimFabric
            ? this.ventTrimFabric
            : this.ventTopFabric[1];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MainTrim:
          this.selected = this.mainTrimFabric
            ? this.mainTrimFabric
            : this.mainFabric[1];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.MainTrimInner:
          this.selected = this.mainTrimFabricInner
            ? this.mainTrimFabricInner
            : this.mainFabric[1];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.Valance:
          this.selected = this.valanceFabric[0];
          this.filters = this.getFilters(this.component);
          break;
        case this.fabricComponent.ValanceAlternating:
          this.selected = this.valanceFabric[1]
            ? this.valanceFabric[1]
            : this.valanceFabric[0];
          this.filters = this.getFilters(this.component);
          break;
      }
    }
  }

  async mounted() {
  try {
    // Fetch all fabrics for the unfiltered list
    this.unfilteredFabrics = await this.fabricService.getAllFabrics();

    // Fetch filtered fabrics for display
    this.fabrics = await this.fabricService.getFabrics({
      grade: this.filters.grade,
      isAdmin: this.$store.getters['global/getRole'] === 'admin',
    });
  } catch (error) {
    console.error("Error fetching fabrics:", error);
  }
}


/*
  * Unless the user role is Admin, Striped fabrics cannot be selected for any designer layout.
*/
  protected showStripes(): void {
    if (
      this.$store.getters['global/getRole'] !== 'admin' &&
      (
        this.preset === option.Preset.D1 ||
        this.preset === option.Preset.D2 ||
        this.preset === option.Preset.D3 ||
        this.preset === option.Preset.D4 ||
        this.preset === option.Preset.D5
      )
    ) {
      this.noStripes = true;
    } else {
      this.noStripes = false;
    }
  }

  get isSpecialFabricComponent(): boolean {
    if (this.component === FabricComponent.Main || this.component === FabricComponent.Binding) {
      return true;
    } else {
      return false;
    }
  }

  protected getFilters(
    component: FabricComponent,
    fabric?: fabric.FabricSwatch
  ): fabric.FabricFilter {
    const swatch = fabric ? fabric : this.selected!;
    const filters = {
      grade: swatch && swatch.grade ? [swatch.grade] 
              : this.filters.grade.length > 1 ? this.filters.grade
              : ["a"],
      color: [],
      design: this.solidsOnly ? "Solids" : "",
      mfr: "",
      weight: this.isSpecialFabricComponent ? "" : this.mainFabric[0].weight!,
    } as fabric.FabricFilter;
    if (component === FabricComponent.Binding) {
      this.setDesign("Solids");
    }
    this.setGrade(swatch && swatch.grade ? swatch.grade : "a");
    return filters;
  }

  protected async getFabrics(): Promise<fabric.FabricSwatch[]> {
  const loader = this.$loading.show({
    isFullPage: false,
    container: this.$refs.spinnerContainerFabric,
  });
  let fabricArray: fabric.FabricSwatch[] = [];
  try {
    const body = {
      grade: this.filters.grade ? this.filters.grade : undefined,
      isAdmin: this.$store.getters['global/getRole'] === 'admin' ? true : false
    }
    const res = await this.fabricService.getFabrics(body);
    fabricArray = res;
    this.$nextTick(() => {
      loader.hide();
    });
  } catch (err) {
    if (err instanceof NotFoundError) {
      NotFoundError.popup(err.message, err.statusCode);
    } else {
      APIError.popup(err.message, err.statusCode);
    }
    loader.hide();
  }
  return fabricArray;
}


protected getFabricByCode(code: string): fabric.FabricSwatch | undefined {
  return this.fabrics.find(fabric => fabric.mfr_code === code);
}
protected getComparisonSwatches(comparisonCodes: string[] | undefined): fabric.FabricSwatch[] {
  if (!comparisonCodes || comparisonCodes.length === 0) return [];

  // Match against the unfilteredFabrics array
  const matchedFabrics = comparisonCodes
  if (!comparisonCodes || comparisonCodes.length === 0) return [];

    return comparisonCodes
      .map((code) => {
        const normalizedCode = code.trim().toLowerCase().replace(/-/g, "");
        return this.unfilteredFabrics.find(
          (fabric) =>
            fabric.mfr_code.toLowerCase().replace(/-/g, "") === normalizedCode
        );
      })
      .filter(Boolean) as fabric.FabricSwatch[];

}


  /** Getter for filtering fabrics with logic to account for all different available filters */
  protected get filteredFabrics(): fabric.FabricSwatch[] {
    let fabricArray: fabric.FabricSwatch[] = [...this.fabrics];
    if (this.component === FabricComponent.Binding) {
      fabricArray = fabricArray.filter((swatch) => {
        const pattern: fabric.FabricSwatch["pattern_group"] =
          swatch.pattern_group!;
        if (pattern.length > 0) {
          return pattern.includes("Solids");
        } else {
          return false;
        }
      });
    }
    if (
      this.component === FabricComponent.Pockets ||
      this.component === this.fabricComponent.VentTrim ||
      this.component === this.fabricComponent.MainTrim ||
      this.component === this.fabricComponent.MainTrimInner
    ) {
      fabricArray = fabricArray.filter((swatch) => {
        const pattern: fabric.FabricSwatch["pattern_group"] =
          swatch.pattern_group!;
        if (pattern.length > 0) {
          return !pattern.includes("Stripes");
        } else {
          return false;
        }
      });
    }
    if (this.searchTerm) {
      // const term = this.searchTerm.toLowerCase().trim();
      const term = this.searchTerm.toLowerCase().trim().replace('-',''); // examines the search term regardless of hyphen
      const matches = fabricArray.filter((swatch) => {
        // return swatch.mfr_code.toLowerCase().indexOf(term) !== -1;
        return swatch.mfr_code.toLowerCase().replace('-','').indexOf(term) !== -1; // examines the manufacture code regardless of hyphen
      });
      const matchesName = fabricArray.filter((swatch) => {
        return swatch.name.toLowerCase().indexOf(term) !== -1;
      });
      const matchesFFcode = fabricArray.filter((swatch) => {
        if (swatch.frankford_code) {
          // return swatch.frankford_code.toLowerCase().indexOf(term) !== -1;
          return swatch.frankford_code.toLowerCase().replace('-','').indexOf(term) !== -1; // examines the frankford code regardless of hyphen
        }
      });
      fabricArray = [...matches, ...matchesName, ...matchesFFcode];
    }
    if (this.filters.mfr) {
      fabricArray = fabricArray.filter((swatch) => {
        return swatch.mfr_handle === this.filters.mfr;
      });
    }
    if (this.filters.design) {
      fabricArray = fabricArray.filter((swatch) => {
        const pattern: fabric.FabricSwatch["pattern_group"] =
          swatch.pattern_group!;
        if (pattern.length > 0) {
          return pattern.includes(this.filters.design);
        } else {
          return false;
        }
      });
    }
    if (this.filters.color.length > 0) {
      const matches: fabric.FabricSwatch[] = [];
      fabricArray.map((swatch) => {
        const colors: fabric.FabricSwatch["color_group"] = swatch.color_group!;
        if (colors.length > 0) {
          colors.map((color) => {
            if (this.filters.color.includes(color)) {
              matches.push(swatch);
            }
          });
        }
      });
      fabricArray = matches;
    }
    if (this.noStripes) {
      fabricArray = fabricArray.filter((swatch) => {
        const pattern: fabric.FabricSwatch["pattern_group"] =
          swatch.pattern_group!;
        if (pattern.length > 0 && pattern.includes("Stripes")) {
          return false;
        } else {
          return true;
        }
      });
    }
    if (this.filters.weight) {
      fabricArray = fabricArray.filter(swatch => {
        return swatch.weight === this.filters.weight;
      });
    }
    return fabricArray;
}

  /** Switcher that communicates with umbrella modeler & saves choice to store based on user selections and specific umbrella component */
  protected async select(swatch: fabric.FabricSwatch): Promise<void> {
    if (!this.component) return;  
    this.selected = swatch; // Ensure the selected swatch is updated globally
    switch (this.component) {
      case this.fabricComponent.Main:
        if (this.mainFabric[1]) {
          if (this.mainFabric[1].weight === swatch.weight) {
            await this.addMainFabric([swatch, this.mainFabric[1]]);
          } else {
            await this.addMainFabric([swatch, swatch]);
          }
          await this.applyLayoutRules();
          this.selected = swatch;
          await this.$viewer.ChangeCanopyFabric(
            swatch.fabric_scale,
            swatch.mfr_code,
            this.mainFabric[1].fabric_scale,
            this.mainFabric[1].mfr_code
          );
        } else {
          await this.addMainFabric([swatch]);
          await this.applyLayoutRules();
          this.selected = swatch;
          await this.$viewer.ChangeCanopyFabric(
            swatch.fabric_scale,
            swatch.mfr_code
          );
        }
        if (this.rib === option.Rib.Pockets) {
        await this.addRibFabric(swatch);
        this.$viewer.ChangeRibAttachment(this.rib, swatch.mfr_code)
        }
        break;
      case this.fabricComponent.MainAlternating:
        await this.addMainFabric([this.mainFabric[0], swatch]);
        await this.applyLayoutRules();
        this.selected = swatch;
        await this.$viewer.ChangeCanopyFabric(
          swatch.fabric_scale,
          this.mainFabric[0].mfr_code,
          swatch.fabric_scale,
          swatch.mfr_code
        );
        break;
      case this.fabricComponent.TopVent:
        this.allowVent(true);
        await this.addVentTopFabric([swatch]);
        await this.applyLayoutRules();
        this.selected = swatch;
        await this.$viewer.ChangeTopVentFabric(
          swatch.fabric_scale,
          swatch.mfr_code
        );
        break;
      case this.fabricComponent.MiddleVent:
        if (this.ventMiddle === option.Panel.Alternating && this.ventMiddleFabric[1]) {
          this.allowVent(true);
          await this.addVentMiddleFabric([swatch, this.ventMiddleFabric[1]]);
          await this.applyLayoutRules();
          this.selected = swatch;
          await this.$viewer.ChangeDblVentFabric(
            swatch.fabric_scale,
            swatch.mfr_code,
            this.ventMiddleFabric[1].fabric_scale,
            this.ventMiddleFabric[1].mfr_code
          );
        } else {
          this.allowVent(true);
          await this.addVentMiddleFabric([swatch]);
          await this.applyLayoutRules();
          this.selected = swatch;
          await this.$viewer.ChangeDblVentFabric(
            swatch.fabric_scale,
            swatch.mfr_code
          );
        }
        break;
      case this.fabricComponent.MiddleAlternating:
        this.allowVent(true);
        await this.addVentMiddleFabric([this.ventMiddleFabric[0], swatch]);
        await this.applyLayoutRules();
        this.selected = swatch;
        await this.$viewer.ChangeDblVentFabric(
          this.ventMiddleFabric[0].fabric_scale,
          this.ventMiddleFabric[0].mfr_code,
          swatch.fabric_scale,
          swatch.mfr_code
        );
        break;
      case this.fabricComponent.Binding:
        await this.addBindingFabric(swatch);
        this.selected = swatch;
        await this.$viewer.ChangeBindingFabric(swatch.mfr_code);
        break;
      // case this.fabricComponent.Pockets:
      //   await this.addRibFabric(swatch);
      //   this.selected = swatch;
      //   await this.$viewer.ChangeRibAttachment(this.rib, swatch.mfr_code);
      //   EventBus.$emit("changePocket", true);
      //   break;
      case this.fabricComponent.MainTrim:
        await this.addMainTrimFabric(swatch);
        this.selected = swatch;
        await this.$viewer.ChangeCanopyTrim(
          swatch.mfr_code,
          this.mainTrimFabricInner ? this.mainTrimFabricInner.mfr_code : null
        );
        break;
      case this.fabricComponent.MainTrimInner:
        await this.addMainTrimFabricInner(swatch);
        this.selected = swatch;
        await this.$viewer.ChangeCanopyTrim(
          this.mainTrimFabric!.mfr_code,
          swatch.mfr_code
        );
        break;
      case this.fabricComponent.VentTrim:
        await this.addVentTrimFabric(swatch);
        this.selected = swatch;
        await this.$viewer.ChangeVentTrim(swatch.mfr_code);
        break;
      case this.fabricComponent.Valance:
        if (this.valanceFabric[1]) {
          await this.addValanceFabric([swatch, this.valanceFabric[1]]);
          this.selected = swatch;
          await this.$viewer.ChangeValanceFabric(
            swatch.fabric_scale,
            swatch.mfr_code,
            this.valanceFabric[1].fabric_scale,
            this.valanceFabric[1].mfr_code
          );
        } else {
          await this.addValanceFabric([swatch]);
          this.selected = swatch;
        this.allowValance(true);
          await this.$viewer.ChangeValanceFabric(
            swatch.fabric_scale,
            swatch.mfr_code
          );
        }
        break;
      case this.fabricComponent.ValanceAlternating:
        await this.addValanceFabric([this.valanceFabric[0], swatch]);
        this.selected = swatch;
        this.allowValance(true);
        await this.$viewer.ChangeValanceFabric(
          this.valanceFabric[0].fabric_scale,
          this.valanceFabric[0].mfr_code,
          swatch.fabric_scale,
          swatch.mfr_code
        );
        break;
    }
  }

  /** set####() methods below set filter values communicated from child FabricFilter.vue */
  protected async setGrade(value: fabric.FabricGrade): Promise<void> {
    if (value === 'a/ap') {
      this.filters.grade = ['a','ap'];
    } else {
      this.filters.grade = [value];
    }
    this.fabrics = await this.getFabrics();
  }

  protected setColor(values: string[] | []): void {
    this.filters.color = values;
  }

  protected setDesign(value: string): void {
    this.filters.design = value;
  }

  protected setMfr(value: string): void {
    this.filters.mfr = value;
  }

  /**
   * Removes the default weight selection of "all" fabrics
   * once a fabric has been manually selected by the user.
   */
  protected setCustomWeight(): void {
    this.isCustomFabricWeight = true;
  }
  
  protected setWeight(value: string, hasCustomWeight = false): void {
    this.filters.weight = value;
    this.isCustomFabricWeight = hasCustomWeight ? true : false;
  }
}

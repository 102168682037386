





















































import { Component, Vue } from "vue-property-decorator";
import CleanHeader from "@/components/designer/header/CleanHeader.vue";
import ViewHeader from "@/components/designer/header/ViewHeader.vue";
import Menu from "@/components/designer/header/Menu.vue";
import Configurator from "@/components/designer/Configurator.vue";
import { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";
import { Roles } from "@/models/user/roles";
import { BaseStore, CanopyStore, FrameStore, LayoutStore, ShapeStore } from "@/mixins/store";
import { ConfigCreator } from "@/mixins/configurator";
import { ConfigFile } from "@/models/configurator/config_file";
import { APIError, NotFoundError } from "@/services/error_service";
import ConfigFileService from "@/services/config_file_service";
import AuthService from "@/services/auth_service";


@Component({
  components: {
    CleanHeader,
    ViewHeader,
    Menu,
    Configurator
  }
})
export default class Designer extends mixins(Auth, LayoutStore, ShapeStore, CanopyStore, BaseStore, FrameStore, ConfigCreator) {
  protected isViewerReady = false;
  protected Roles = Roles;
  protected configFileService = new ConfigFileService();
  protected prevLocalStorage: any = null;
  protected fileShareId = '';
  protected query: string | undefined = '';
  protected authService = new AuthService();
  protected isReadyForConfigurator = false;
  protected configFile: ConfigFile | null = null;



  async created() {
    this.query = this.$route.query.design as string;
    const loader = this.$loading.show(
      { opacity: 1 },
      {
        before: this.$createElement(
          "h1",
          "Loading custom umbrella view..."
        ),
      }
    );
    const idx = this.query.indexOf('?') as number;
    if (idx !== -1) {
      this.fileShareId = this.query.substring(0,idx);
      const redirect = this.query.substring(idx);
      if (redirect.includes("redirect")) {
        await this.checkAuth();
      }
    } else {
      this.fileShareId = this.query;
    }
    if (!this.$route.query.design) {
      loader.hide();
      this.$router.push("/collectionmenu");
      return;
    } else if (this.fileShareId !== 'redirect') {
      this.configFile = await this.fetchSharedConfigFile(this.fileShareId);
      if (this.configFile) {
        await this.transformConfigFileAndSaveToStore(this.configFile);
      } else {
        this.$router.push("/error");
      }
    }
    this.isReadyForConfigurator = true;
    loader.hide();
  }

  protected async fetchSharedConfigFile(shareId: string): Promise<ConfigFile | null> {
    let file: ConfigFile | null = {} as ConfigFile;
    try {
      const res = await this.configFileService.getShareFile(shareId);
      file = res;
    } catch (err) {
      file = null;
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
    return file;
  }

  protected ready(): void {
    this.isViewerReady = true;
  }

  protected editUmbrella(): void {
    this.$router.push("/?design=" + this.fileShareId + "&edit=true");
  }

  // protected createNewUmbrella(): void {
  //   this.$router.push("/collectionmenu");
  // }
}

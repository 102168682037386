







import UIkit from "uikit";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class fabricDisclaimer extends Vue {
 
}

































































































































































import { Component, Prop } from "vue-property-decorator";
import * as fabric from "@/models/products/fabric";
import { mixins } from "vue-class-component";
import { StockMixin } from "@/mixins/stock";
import { FabricComponent } from "@/models/configurator/fabric_components";

@Component
export default class FabricFilter extends mixins(StockMixin) {
  /**
   * For filtering purposes, all filters are case sensitive to their match to what is returned by API
   * Only change to match how these values are being returned by API
   */
  @Prop() filters!: fabric.FabricFilter;
  @Prop({ default: false }) solidsOnly!: boolean;
  @Prop({ default: false }) noStripes!: boolean;
  @Prop({ default: false }) isSpecialFabricComponent!: boolean;
  @Prop() mainFabric!: fabric.FixedFabricArray;
  @Prop({ default: null }) component!: FabricComponent | null;
  @Prop({ default: false }) isCustomFabricWeight!: boolean;
  protected grades: string[] = ["a/ap", "a", "ap", "b", "c", "d", "e", "f"];
     // Categories below are set by client
  protected colors: string[] = [
    "Beige",
    "Black",
    "Blue",
    "Brown",
    "Gold",
    "Grey",
    "Green",
    "Ivory",
    "Orange",
    "Pink",
    "Purple",
    "Red",
    "Turquoise",
    "White",
    "Yellow"
  ];
  protected designs: string[] = [
    "Solids",
    "Stripes",
    "Textures",
    "Geometrics",
    "Tweeds"
  ];
  protected mfrs: string[] = ["sunbrella", "sattler", "recasens"];
  protected weights: string[] = ["8", "9"];
  protected showFabricScrollArrow = false;

  mounted() {
    const container = document.getElementById("filters-selector") as HTMLElement;
    if (container) {
      container.addEventListener("scroll", this.handleScroll);
      this.updateScrollArrowVisibility();
    }
  }

  beforeDestroy() {
    const container = document.getElementById("filters-selector") as HTMLElement;
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }
  }

  protected handleScroll() {
    this.updateScrollArrowVisibility();
  }

  protected isScrollbarVisible(container: HTMLElement): boolean {
    return container.scrollHeight > container.clientHeight;
  }

  protected updateScrollArrowVisibility() {
    this.$nextTick(() => {
      const container = document.getElementById("filters-selector") as HTMLElement;
      if (container) {
        this.showFabricScrollArrow =
          this.isScrollbarVisible(container) &&
          container.scrollTop + container.clientHeight < container.scrollHeight;
      }
    });
  }

  protected scrollToBottom(): void {
    const container = document.getElementById("filters-selector") as HTMLElement;
    container.scrollTo({
      top: container.scrollHeight,
      behavior: "smooth",
    });
  }

  public makeSentenceCase(sentence: string) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  protected highlightColor(color: string): boolean {
    let highlight = false;
    if (this.filters.color.length > 0 && this.filters.color.includes(color)) {
      highlight = true;
    }
    return highlight;
  }

  /** The following set####()methods communicate user selected filters to the fabric swatch display (FabricSelector.vue) */
  protected setGrade(value: string): void {
    this.$emit("setGrade", value);
  }

  /** Color filters are additive, whilst other filters are just one at a time */
  protected setColor(value: string): void {
    const colorArray =
      this.filters.color.length > 0 ? [...this.filters.color] : [];
    if (!value) {
      // clear color filters
      this.$emit("setColor", []);
    } else if (this.filters.color.includes(value)) {
      // if the color selected is already in the color filter array when it is clicked, remove it
      const newColors = colorArray.filter(color => color !== value);
      this.$emit("setColor", newColors);
    } else {
      colorArray.push(value); // if the colors selected is not present in the color filter array, add it to the filters
      this.$emit("setColor", colorArray);
    }
  }

  protected setDesign(value: string): void {
    this.$emit("setDesign", value);
  }

  protected setMfr(value: string): void {
    this.$emit("setMfr", value);
  }

  protected setWeight(value: string): void {
    if (!this.isCustomFabricWeight) {
      this.$emit("setCustomWeight");
    }
    this.$emit("setWeight", value);
  }
}

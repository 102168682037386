import { UserState } from "./types";
import { User } from "@/models/user/user";

export const getUser = (state: UserState): User | null => {
  return state.user;
};

export const getFileId = (state: UserState): string | null => {
  return state.fileBeingEdited;
};

export const getMsrpMultiplier = (state: UserState): number => {
  let multiplier = 1;
  if (
    state.user &&
    state.user.customer_info &&
    state.user.customer_info.msrp_multiplier
  ) {
    multiplier = state.user.customer_info.msrp_multiplier;
  }
  return multiplier;
};

import { Notification } from "@/models/notification";
import axios, { AxiosResponse } from "axios";

axios.defaults.withCredentials = true;
// axios.defaults.headers = "Access-Control-Allow-Origin";

export default class NotificationService {
  public async fetchNotifications(): Promise<Array<Notification>> {
    let notifications: Array<Notification> = [];
    const url =
      process.env.VUE_APP_API_NODE_URL + "/notifications/getNotifications";
    const body = {
      releaseNotesUrl: process.env.VUE_APP_NOTIFICATIONS_URL as string
    };
    try {
      const res: AxiosResponse = await axios.get(url, {
        params: body
      });
      notifications = res.data.data.notifications;
      return notifications;
    } catch (error) {
      console.error("Notifications could not be obtained.");
      return notifications;
    }
  }
}

import * as fabric from "@/models/products/fabric";
import axios, { AxiosResponse } from "axios";
import { APIError, NotFoundError } from "./error_service";

axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

/**
 * Creates a new Fabric Service

 * @example
 * const fabricService = new FabricService();
 * fabricService.getFabrics();
 */

export default class FabricService {
  /**
   * Fetch filtered fabrics
   * @param {object} optionsObject optional filters, grade & mfr
   * @returns {FabricSwatch[]} - response array of fabric swatches
   */
  public async getFabrics(optionsObject?: {
    grade?: fabric.FabricGrade[];
    mfr?: fabric.FabricMfr;
    isAdmin?: boolean;
  }): Promise<fabric.FabricSwatch[]> {
    let fabrics;
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.grade) {
        for (const grade of optionsObject.grade) {
          counter++;
          if (counter > 1) {
            char = "&";
          }
          url += char + "grade=" + grade;
        }
      }
      if (optionsObject.mfr) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "mfr=" + optionsObject.mfr;
      }
      if(optionsObject.isAdmin){
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "admin=" + optionsObject.isAdmin;
      }
    }
    try {
      const res: AxiosResponse = await axios.get(url, { withCredentials: true });
      fabrics = res.data.data;
    } catch (err) {
      throw new APIError(
        "Unable to get fabric data. Please try again.",
        err.response?.status || 500
      );
    }
    return fabrics;
  }

  /**
   * Fetch all fabrics without filters
   * @returns {FabricSwatch[]} - response array of all fabric swatches
   */
  public async getAllFabrics(): Promise<fabric.FabricSwatch[]> {
    let fabrics;
    const url = process.env.VUE_APP_API_NODE_URL + "/fabrics";
    try {
      const res: AxiosResponse = await axios.get(url, { withCredentials: true });
      fabrics = res.data.data;
    } catch (err) {
      throw new APIError(
        "Unable to get all fabric data. Please try again.",
        err.response?.status || 500
      );
    }
    return fabrics;
  }

  /**
   * Fetch a single fabric by mfr_code
   * @param {number | string} mfr_code - The manufacturer code
   * @returns {FabricSwatch} - response object of the requested fabric
   */
  public async getSingleFabric(
    mfr_code: number | string
  ): Promise<fabric.FabricSwatch> {
    let fabric;
    const url = process.env.VUE_APP_API_NODE_URL + "/fabrics/" + mfr_code;
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url);
      fabric = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Fabric swatch not found.",
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return fabric;
  }
}

import { Roles } from "@/models/user/roles";
import axios, { AxiosResponse } from "axios";
import {
  APIError,
  AuthError,
  BadRequest,
  NotFoundError,
  PermissionsError
} from "./error_service";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_NODE_URL;

export default class AuthService {
  public async login(email: string, pw: string): Promise<{ role: Roles }> {
    let user = {} as { role: Roles };
    try {
      const res: AxiosResponse = await axios.post("/auth/login", {
        email: email,
        password: pw
      });
      user = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return user;
  }

  public async checkAuth(): Promise<void> {
    try {
      await axios.get("/auth/checkauth");
    } catch (err) {
      throw new AuthError(err.response.data.message, err.response.status);
    }
  }

  public async logout(): Promise<void> {
    try {
      await axios.post("/auth/logout");
    } catch (err) {
      throw new APIError(
        "Something went wrong, please try again.",
        err.response.status
      );
    }
  }

  public async requestNewPassword(email: string): Promise<void> {
    try {
      await axios.patch("/auth/forgot-password", { email: email });
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status);
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
  }

  public async resetPassword(
    newPw: string,
    token: string,
    userId: string
  ): Promise<{ role: Roles }> {
    let res;
    try {
      res = await axios.post("/auth/reset-password", {
        password: newPw,
        token: token,
        userId: userId
      });
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return res.data.data;
  }

  public async resendEmailVerification(email: string): Promise<void> {
    try {
      await axios.post("/auth/resend-verify", { email: email });
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
  }

  public async verifyRegistration(
    token: string,
    userId: string
  ): Promise<{ role: Roles }> {
    let res;
    try {
      res = await axios.get(`/auth/verify?token=${token}&id=${userId}`);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status);
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return res.data.data;
  }
}

import { Notification } from "@/models/notification";
import { Roles } from "@/models/user/roles";

export type GlobalState = {
  isLoading: boolean;
  isLoggedIn: boolean;
  userRole: Roles | null;
  notifications: Notification[];
};

export type GlobalContext = {
  state: GlobalState;
  dispatch: (action: GlobalActions, payload?: any) => any;
  commit: (mutation: GlobalMutations, payload: any) => any;
};

export enum GlobalMutations {
  SetLoading = "setLoading",
  SetLoggedIn = "setLoggedIn",
  SetRole = "setRole",
  SetNotifications = "setNotifications"
}

export enum GlobalActions {
  AddLoading = "addLoading",
  AddLoggedIn = "addLoggedIn",
  AddRole = "addRole",
  AddNotifications = "addNotifications"
}

export enum GlobalGetters {
  GetLoading = "getLoading",
  GetLoggedIn = "getLoggedIn",
  GetRole = "getRole",
  GetNotifications = "getNotifications"
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-info-bar"},[_c('div',{staticStyle:{"height":"50px","width":"110%"}},[_c('a',{staticClass:"prevnext prev",on:{"click":_vm.stepBack}},[_c('span',[_vm._v("BACK")])]),_c('a',{staticClass:"prevnext next",on:{"click":_vm.stepForward}},[_c('span',[_vm._v("NEXT")])])]),_vm._v(" "),_c('div',{staticClass:"uk-grid",attrs:{"name":"umbrella-info-and-pricing"}},[_c('div',{staticClass:"umbrella-info"},[_c('div',[_c('h1',[_vm._v("\n          "+_vm._s(_vm.collection.display_name.indexOf(" ") != -1
              ? _vm.formatTitle(_vm.collection.display_name, "a")
              : _vm.collection.display_name)+"\n          "),_c('em',[_vm._v(_vm._s(_vm.collection.display_name.indexOf(" ") != -1
              ? _vm.formatTitle(_vm.collection.display_name, "b")
              : null))])])]),_vm._v(" "),_c('div',{staticClass:"modal"},[_c('p',{staticClass:"info-text"},[_vm._v("\n          "+_vm._s(_vm.umbrellaModel ? _vm.umbrellaModel.model : "NO MODEL CHOSEN")+"\n          ("+_vm._s(_vm.umbrellaModel.size[0])+" "+_vm._s(_vm.umbrellaModel.shape[0])+")\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"umbrella-pricing uk-flex uk-flex-right"},[_c('div',[_c('div',{staticClass:"\n            uk-flex\n            uk-flex-row@xl\n            uk-flex-column\n            uk-flex-bottom@xl\n            uk-flex-middle@xl\n            uk-flex-right\n          "},[_c('div',{staticClass:"uk-flex uk-flex-left@xl uk-flex-right uk-flex-bottom"},[(!_vm.isLoggedIn)?_c('p',{staticClass:"info-text"},[_c('a',{on:{"click":function($event){return _vm.$router.push('/login?return=designer')}}},[_vm._v("SIGN IN")]),_vm._v("\n              TO SEE PRICE\n            ")]):_vm._e(),_vm._v(" "),(
                _vm.isLoggedIn &&
                  _vm.userRole !== _vm.Roles.Unverified &&
                  _vm.userRole !== _vm.Roles.Basic
              )?_c('p',{staticClass:"info-text"},[_vm._v("\n              "+_vm._s(_vm.msrpMultiplier === 1 ? "MSRP" : "COST")+"\n            ")]):_vm._e(),_vm._v(" "),(
                _vm.isLoggedIn &&
                  _vm.userRole !== _vm.Roles.Unverified &&
                  _vm.userRole !== _vm.Roles.Basic
              )?_c('button',{staticClass:"icon-info uk-flex-none"},[_c('InfoIcon')],1):_vm._e(),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),_c('strong',[(
              _vm.isLoggedIn &&
                _vm.userRole !== _vm.Roles.Unverified &&
                _vm.userRole !== _vm.Roles.Basic
            )?_c('Pricing',{on:{"updateSKU":_vm.updateSKU}}):_vm._e()],1),_vm._v(" "),_c('LeadTime')],1)])]),_vm._v(" "),(_vm.isLoggedIn)?_c('div',{staticClass:"uk-grid uk-margin-small-bottom",attrs:{"name":"umbrella-sku-and-quickship"}},[_c('div',{staticClass:"uk-width-expand uk-text-small"},[_vm._v("\n      SKU: "+_vm._s(_vm.hasLongSku ? _vm.shortSku : _vm.sku)),(_vm.hasLongSku)?_c('span',[_vm._v("...")]):_vm._e()]),_vm._v(" "),(_vm.hasLongSku)?_c('div',{staticClass:"right tt sku",attrs:{"uk-dropdown":"animation: uk-animation-slide-top-small; delay-hide: 100; pos: top-right;"}},[_c('div',{staticClass:"dropdown-wrap"},[_c('p',[_vm._v("SKU: "+_vm._s(_vm.sku))])])]):_vm._e(),_vm._v(" "),(_vm.userRole === _vm.Roles.Admin)?_c('div',{staticClass:"qs-icon uk-width-auto"},[_c('a',{attrs:{"uk-tooltip":"Save as a QuickShip item"},on:{"click":_vm.getImagesQuickShip}},[_c('img',{attrs:{"src":require("../../../assets/images/quickship_icon_2.png")}})])]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',[_c('button',{staticClass:"button",class:{ 'no-click': _vm.disable },attrs:{"disabled":_vm.disable},on:{"click":_vm.getImagesQuote}},[_vm._v("\n      Request A Quote\n    ")]),_vm._v(" "),_c('Download'),_vm._v(" "),(_vm.isLoggedIn && !_vm.disable)?_c('span',{on:{"click":_vm.getImagesShare}},[(_vm.isLoggedIn)?_c('Share',{attrs:{"showDialog":_vm.showShareDialog,"shareLink":_vm.shareLink},on:{"close":_vm.closeShare}}):_vm._e()],1):(_vm.disable)?_c('button',{staticClass:"button no-click",attrs:{"disabled":""}},[_vm._v("\n      SHARE\n    ")]):_c('span',{attrs:{"uk-tooltip":"Please sign in or request an account to access this feature."}},[_c('button',{staticClass:"button auth-required",attrs:{"disabled":""}},[_vm._v("SHARE")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right tt",attrs:{"uk-dropdown":"animation: uk-animation-slide-top-small; delay-hide: 100; pos: top-right;"}},[_c('div',{staticClass:"dropdown-wrap"},[_c('p',[_vm._v("\n                  Prices shown are only an estimate. Actual pricing may\n                  differ.\n                ")])])])}]

export { render, staticRenderFns }
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import { UserState } from "./types";

const state: UserState = {
  user: null,
  fileBeingEdited: "",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};












































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import UIkit from "uikit";
import { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";
import AuthService from "@/services/auth_service";
import { AuthError, GenericError } from "@/services/error_service";
import { Notification } from "@/models/notification";
import NotificationService from "@/services/notification_service";
import UserService from "@/services/user_service";

@Component
export default class Header extends mixins(Auth) {
  @Prop({ default: false }) profileView?: boolean;
  protected authService = new AuthService();
  protected notificationService = new NotificationService();
  protected userService = new UserService();
  protected bellAnimationTimeout = 1000;
  private notificationsUrl = process.env.VUE_APP_NOTIFICATIONS_URL;

  get notificationContainerTimeout(): number {
    return this.bellAnimationTimeout + 1000;
  }

  get unreadNotificationCount(): number {
    return this.notifications.filter(notification => {
      const lastViewedDate = this.user.login_history.viewed_notifications;
      const notificationDate = notification.publishDate;
      return lastViewedDate ? notificationDate > lastViewedDate : true;
    }).length;
  }

  get hasUnreadNotifications(): boolean {
    return this.unreadNotificationCount > 0;
  }

  @Watch("notifications")
  protected async updatedNotifications(): Promise<void> {
    await this.showUserNotifications();
  }

  protected async showUserNotifications(): Promise<void> {
    try {
      if (this.notifications.length === 0 || !this.user || !this.isLoggedIn) {
        return;
      }
      if (this.$route.path.includes("designer")) {
        this.bellAnimationTimeout += 2000;
      }
      // Only show the modal if the user is logged in and it's their first visit
      if (this.hasUnreadNotifications) {
        // Wait for the DOM to update before showing the animation
        this.$nextTick(() => {
          this.showNotificationIndicator();
        });
      }
      if (!this.user.login_history.viewed_notifications) {
        // Wait for the DOM to update before opening the modal
        this.$nextTick(() => {
          setTimeout(() => {
            this.openNotificationModal();
          }, this.notificationContainerTimeout);
        });
      }
      // Add document click listener
      document.addEventListener("click", this.handleDocumentClick);
    } catch (error) {
      console.error(error);
    }
  }

  beforeDestroy(): void {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  private handleDocumentClick = (event: Event): void => {
    const dropdown = document.querySelector(".notification-container");
    const bell = document.querySelector(".bell-notification");

    if (dropdown && !dropdown.contains(event.target as Node) && !bell?.contains(event.target as Node)) {
      const uikitDropdown = UIkit.dropdown(dropdown);
      if (uikitDropdown) {
        // Close the dropdown if it's open
        uikitDropdown.hide();
      }
    }
  };

  protected openNotificationModal(): void {
    const dropdown = document.querySelector(".notification-container");
    if (dropdown) {
      try {
        const uikitDropdown = UIkit.dropdown(dropdown);
        uikitDropdown.show(); // Attempt to show the dropdown
      } catch (error) {
        console.error("Error opening UIKit dropdown:", error);
      }
    } else {
      console.error("Dropdown element not found.");
    }
  }

  /**
   * Show a shake animation on the bell icon to indicate new notifications
   * after a designated timeout (for UX purposes).
   */
  protected showNotificationIndicator(): void {
    console.log("this.bellAnimationTimeout", this.bellAnimationTimeout);
    setTimeout(() => {
      const bell = document.querySelector(".bell-notification");
      if (bell) {
        bell.classList.add("uk-animation-shake");
        setTimeout(() => {
          bell.classList.remove("uk-animation-shake");
        }, 1000);
      }
    }, this.bellAnimationTimeout);
  }

  protected handleNotificationClick(): void {
    this.userViewedNotifications();
  }

  protected handleNotificationScroll(): void {
    this.userViewedNotifications();
  }

  protected async userViewedNotifications(): Promise<void> {
    if (this.hasUnreadNotifications) {
      const updatedUser = await this.userService.updateNotificationsTimestamp(
        this.user
      );
      await this.setUserInfo(updatedUser);
    }
  }

  protected closeModal(): void {
    const dropdown = document.querySelector(".notification-container");
    if (dropdown) {
      try {
        // Use UIKit's API to close the dropdown
        const uikitDropdown = UIkit.dropdown(dropdown);
        uikitDropdown.hide(); // Properly close the dropdown
      } catch (error) {
        console.error("Error closing UIKit dropdown:", error);
      }
    } else {
      console.error("Dropdown element not found.");
    }
  }

  protected toggleModal(): void {
    UIkit.modal(
      document.getElementById("start-over-modal") as HTMLElement
    ).show();
  }

  public async logout(): Promise<void> {
    try {
      this.addFileBeingEditedId("");
      this.setLoggedIn(false);
      this.setUserRole(null);
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }

  protected async getNotifications(): Promise<Array<Notification>> {
    return await this.notificationService.fetchNotifications();
  }

  protected get latestNotifications(): Array<Notification> {
    return this.notifications.filter((_, index) => index < 5);
  }

  protected isImage(source: string): boolean {
    return /\.(jpg|jpeg|png|gif|webp|svg|bmp|ico)$/i.test(source);
  }

  protected isVideo(source: string): boolean {
    return /\.(mp4|webm|ogg)$/i.test(source);
  }

  /*
  loadHeadwayScript(): void {
    const script = document.createElement("script");
    script.src = "https://cdn.headwayapp.co/widget.js";
    script.async = true;
    script.onload = this.initializeHeadway;
    document.body.appendChild(script);
  }

  initializeHeadway() {
    const config = {
      selector: ".bell-notification", // CSS selector or direct DOM element
      account: "yZwGlx", // Your Headway account ID
    };

    // Ensure Headway is defined before trying to initialize it
    if (typeof (window as any).Headway !== "undefined") {
      (window as any).Headway.init(config);
    } else {
      console.error("Headway is not defined.");
    }
  }
  */
}













































import { FabricSwatch } from "@/models/products/fabric";
import { Component, Vue, Prop } from "vue-property-decorator";
import { FabricManufacturer } from "@/models/products/fabric";

@Component
export default class Swatch extends Vue {
  @Prop() img!: string | null;
  @Prop() discontinued!: boolean | null;
  @Prop() comparisons!: FabricSwatch["comparisons"];
  @Prop() mfr!: FabricSwatch["mfr"] | null;

  get cdnUrl(): string {
    return process.env.VUE_APP_CDN_URL + "/fabric_swatches/";
  }

  /**
   * Only show the comparison feature on fabrics that have a Recasens comparison,
   * not on the comparable Recasens fabrics themselves.
   */
  get filteredComparisons() {
    return this.mfr?.toLocaleLowerCase() === FabricManufacturer.Recasens ||
      !this.comparisons
      ? []
      : this.comparisons;
  }
}






















































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";

@Component
export default class ViewHeader extends mixins(Auth) {
  @Prop({ default: false }) profileView?: boolean;
}

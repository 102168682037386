import "@/components";
import "./assets/css/uikit.min.css";
import "vue-loading-overlay/dist/vue-loading.css";

import App from "./App.vue";
import Loading from "vue-loading-overlay";
import Vue from "vue";
import Vuelidate from "vuelidate";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// @ts-ignore
import Icons from "../node_modules/uikit/dist/js/uikit-icons.min.js";
import UIkit from "uikit";

// @ts-ignore
UIkit.use(Icons);
Vue.use(Vuelidate);
Vue.use(
  Loading,
  {
    color: "#333F48",
    loader: "dots",
    canCancel: false,
    isFullPage: true,
    opacity: 0.8
  },
  {}
);
Vue.component("loading", Loading);
Vue.config.productionTip = false;

/** Use the following filters in templates using mustache interpolation to format text.
 * @example {{ price | currency }} formats the 'price' according to 'currency' function
 * @example {{ orderDate | date }} formats the 'orderDate' according to 'date' function
 */
Vue.filter("currency", function(value: number): string {
  const num = (Math.round(value * 100) / 100).toFixed(2);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("date", function(date: Date): string {
  const dt = new Date(date);
  return dt.toLocaleDateString("en-US");
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
